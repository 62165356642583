import styled from "styled-components";

interface ApplicantInfoBlockProps {
  isLast?: boolean;
}

export const ApplicantCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplicantContainer = styled.div`
  display: flex;
  border: 1px solid #d4dcec;
  border-radius: 8px;
  font-size: 12px;
  line-height: 125%;
  overflow: hidden;
`;

export const ApplicantInfoContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 6px 6px 6px 6px;
`;

export const ApplicantInfoBlock = styled.div<ApplicantInfoBlockProps>`
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: ${(props: any) => (props.isLast ? "flex-end" : "none")};
  text-align: ${(props: any) => props.isLast && "end"};
`;

export const ContactLinks = styled.a`
  color: #2f80ed;
  text-decoration: none;
  word-break: break-word;
`;
