import React from "react";
import { useRef, useState } from "react";
import {
  FlowContext,
  FlowContextProvider,
} from "../../../hooks/useFlowContext";
import { QueryClientProvider, QueryClient } from "react-query";
import UploadFile from "../../../components/Files/FileUpload";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import { InputList } from "../../../components/Input/InputList";

import type {
  RefinanceFormData,
  ExtendedRefinancingDetails,
  RefinancingDetails,
} from "./types";
import type { ComponentConfig } from "../types";
import { TabButton } from "../../../components/Buttons/TabButton";
import { TabContainer } from "../../../components/Buttons/styles";
import type {
  DynamicTableFileViewProps,
  TableColumn,
} from "../../../components/Table/types";
import DynamicTable, { PdfViewer } from "../../../components/Table/Table";
import GenericButton from "../../../components/Buttons/GenericButton";
import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";
import { ListInfo } from "../../../components/Info/ListInfo";
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { uploadFile } from "../../../utils/files";
import { containsLetters } from "../../../utils/containsLetters";
import {
  CollapseableInfoContent,
  CollapseableInfoHeader,
} from "../../../components/Info/styles";
import CloseCircle from "../../../components/Icons/CloseCircle";
import {
  getMessageTasks,
  triggerMessageTask,
} from "../../../services/flow.service";
import { LoadingSpinner } from "../../../components/Spinner/LoadingSpinner";
import axios from "axios";
import type { TaskProps } from "../../../types/TaskProps";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: true,
      staleTime: 1000 * 10,
    },
  },
});

const PoaAndRefinanceDocumentation = (props: TaskProps) => {
  const { flow, user, task, complete, save } = props;
  const { flowId } = flow;

  const decisionErrorMessage = "Please select a decision.";
  const noErrorMessage = "";

  const [showOverview, setShowOverview] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string>(noErrorMessage);
  const [comment, setComment] = useState<string>(task?.data?.comment ?? "");
  const [decision, setDecision] = useState<boolean | "">(
    task?.data?.decision ?? "",
  );

  const [showFile, setShowFile] = useState<boolean>(false);
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const savedrefinanceDetails: ExtendedRefinancingDetails[] =
    task?.data?.refinancingDetails;
  const lastRefinanceDetailsId = savedrefinanceDetails
    ? Number.parseInt(
        // The Id of the last refinance detail that where saved
        savedrefinanceDetails[savedrefinanceDetails.length - 1].id,
      )
    : 0;

  const refinanceDetailsId = useRef(lastRefinanceDetailsId);
  const {
    documents,
    refinancingDetails,
    applicantInfo,
    offer,
    caseworkerUploadedDocuments,
  } = task.context;

  const [customerUploadedDocuments, setCustomerUploadedDocuments] = useState<
    any[]
  >(documents ?? []);

  const [uploadedFiles, setUploadedFiles] = useState<any[]>(
    caseworkerUploadedDocuments ?? [],
  );

  let extendedRefinancingDetails: ExtendedRefinancingDetails[] = [];
  if (!savedrefinanceDetails) {
    extendedRefinancingDetails = refinancingDetails.map(
      (details: ExtendedRefinancingDetails, index: number) => {
        refinanceDetailsId.current++;
        return {
          ...details,
          order: refinanceDetailsId.current,
          id: refinanceDetailsId.current.toString(),
        };
      },
    );
  }

  const [newRefinancingDetails, setNewRefinancingDetails] = useState<
    ExtendedRefinancingDetails[]
  >(savedrefinanceDetails ?? extendedRefinancingDetails);

  const loanDetails = {
    bankAccount: applicantInfo.bankAccountNumber,
    finalLoanAmount: offer.finalLoanAmount,
    totalRefinanceAmount: Number.parseFloat(
      newRefinancingDetails
        // @ts-ignore Ignoring the error because parseFloat works even if the value is a number.
        .reduce((sum, detail) => sum + Number.parseFloat(detail.amount), 0)
        .toFixed(2),
    ),
  };
  let amountSum = 0;

  const handleFileUpload = async (files: any[]) => {
    setIsLoading(true);
    try {
      setErrorMessages(noErrorMessage);
      const { uploadedFilesResponse } = await uploadFile(files);
      const messageTasks = await getMessageTasks(flowId);
      const mapAttachmentTask = messageTasks.find(
        (task: any) => task.taskType === "caseworker-upload-document",
      );
      await triggerMessageTask(
        mapAttachmentTask.taskId,
        uploadedFilesResponse[0],
      );
      const attachmentKey = uploadedFilesResponse[0].filename
        .replace(/ /g, "-")
        .replace(/\./g, "-");
      const newFile = [
        {
          ...uploadedFilesResponse[0],
          attachmentKey,
          filename: uploadedFilesResponse[0].filename,
          name: attachmentKey,
        },
      ];
      setUploadedFiles(uploadedFiles.concat(newFile));
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorMessages(
          `Could not upload document. Received error: ${JSON.stringify(error?.message)}`,
        );
      }
      setIsLoading(false);
    }
  };

  let formData: RefinanceFormData = {
    refinancingDetails: newRefinancingDetails.map(
      (detail: RefinancingDetails) => {
        const { ...visibleDetails } = detail;
        return visibleDetails;
      },
    ),
    decision: decision,
    comment: comment,
    files: uploadedFiles,
  };

  const onComplete = async () => {
    if (decision === "") {
      setErrorMessages(decisionErrorMessage);
      return;
    }
    if (errorMessages === decisionErrorMessage) {
      setErrorMessages(noErrorMessage);
    }
    if (errorMessages !== noErrorMessage) {
      return;
    }
    if (decision === true && offer.finalLoanAmount - amountSum < 0) {
      setErrorMessages(
        "The refinance amount cannot be higher than total loan amount.",
      );
      return;
    }

    if (uploadedFiles.length > 0) {
      const alreadyUploadedFiles = uploadedFiles.filter((file) => {
        return !!file.fileId;
      });
      const newFiles = uploadedFiles.filter((file) => {
        return !file?.fileId;
      });
      let newFilesResponse = [];
      if (newFiles.length > 0) {
        const { uploadedFilesResponse } = await uploadFile(newFiles);
        newFilesResponse = uploadedFilesResponse;
      }
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: newFilesResponse.concat(alreadyUploadedFiles),
      };
    } else {
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            if (visibleDetails.amount === "") {
              visibleDetails.amount = 0;
            }
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
      };
    }
    complete(
      formData,
      () => {},
      (e: any) => {
        setErrorMessages(e?.detail);
      },
    );
  };

  const onSave = async () => {
    if (uploadedFiles.length > 0) {
      const alreadyUploadedFiles = uploadedFiles.filter((file) => {
        return !!file.fileId;
      });
      const newFiles = uploadedFiles.filter((file) => {
        return !file?.fileId;
      });
      let newFilesResponse = [];
      if (newFiles.length > 0) {
        const { uploadedFilesResponse } = await uploadFile(newFiles);
        newFilesResponse = uploadedFilesResponse;
      }
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: newFilesResponse.concat(alreadyUploadedFiles),
      };
    } else {
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: uploadedFiles,
      };
    }
    save(
      formData,
      () => console.log("success"),
      (error: any) => console.error({ error }),
      true,
    );
  };

  const addNewInput = () => {
    refinanceDetailsId.current++;
    infoConfig = handleClickAddNewButton(
      refinanceDetailsId.current,
      setNewRefinancingDetails,
      infoConfig,
      handleDelete,
      setErrorMessages,
    );
  };

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <ApplicantCollection
          nationalId={applicantInfo.nationalId}
          firstName={applicantInfo.firstName}
          lastName={applicantInfo.lastName}
          mobileNumber={applicantInfo.mobileNumber}
          emailAddress={applicantInfo.emailAddress}
        />
      ),
      order: 1,
    },
    {
      component: <ListInfo title="Loan details" context={loanDetails} />,
      order: 2,
    },
    {
      component: (
        <RadioButtonGroup
          radioButtonValue={decision}
          onChange={(e: string) =>
            e === "true" ? setDecision(true) : setDecision(false)
          }
          title="Verify refinance details"
          options={[
            { value: true, label: "Approve - send to disbursement" },
            { value: false, label: "Reject application" },
          ]}
          id="verify-refinance"
        />
      ),
      order: 3,
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment}
        />
      ),
      order: 4,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 7,
      hide: errorMessages === noErrorMessage,
    },
  ];
  let infoConfig: ComponentConfig[] = [];

  const handleDelete = (idToDelete: string | undefined) => {
    setNewRefinancingDetails((prevDetails) =>
      prevDetails.filter((detail) => {
        return detail.id !== idToDelete;
      }),
    );
    infoConfig = removeComponent(idToDelete, infoConfig);
  };

  const handleTabView = (tab: string) => {
    if (tab === "Overview") {
      setShowOverview(true);
    }
    if (tab === "Files") {
      setShowOverview(false);
    }
  };

  const removeComponent = (
    idToDelete: string | undefined,
    infoConfig: ComponentConfig[],
  ) => {
    const newInfoConfig = infoConfig.filter((config) => {
      if (config.component && React.isValidElement(config.component)) {
        if (config.component.type !== InputList) {
          return true;
        }
        const props = config.component.props;
        if (
          Array.isArray(props.infoLists) &&
          props.infoLists[0]?.id !== idToDelete
        ) {
          return true;
        }
      }
      return false;
    });

    return newInfoConfig;
  };

  const handleOnBlur = (
    key: string,
    e: React.FocusEvent<HTMLInputElement>,
    id: string | undefined,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
  ) => {
    let value: string | number = e.target.value;
    switch (key) {
      case "amount":
        if (containsLetters(value)) {
          setErrorMessages(`Illegal characters in ${value}.`);
        } else {
          setErrorMessages("");
        }
        // Replace comma with dot, before parsing to float and rounding up to 2 decimals. E.g: 12,178 => 12.18
        value = Number.parseFloat(value.replace(",", ".")).toFixed(2);
        break;
      default:
        break;
    }

    setNewRefinancingDetails((prevDetails: ExtendedRefinancingDetails[]) => {
      const updatedDetails = prevDetails.map((detail) => {
        if (detail.id !== id) {
          return detail;
        }
        return {
          ...detail,
          [key]: value,
        };
      });
      return updatedDetails;
    });
  };

  const handleClickAddNewButton = (
    refinanceDetailsId: number,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
    infoConfig: ComponentConfig[],
    handleDelete: (id: string) => void,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
  ) => {
    const newObject: ExtendedRefinancingDetails = {
      accountNumber: "",
      bankName: "",
      id: refinanceDetailsId.toString(),
      paymentReference: "",
      amount: "",
      order: refinanceDetailsId,
    };
    const { id, order, ...visibleDetails } = newObject;
    const context = visibleDetails;

    setNewRefinancingDetails((prevDetails) => [...prevDetails, newObject]);
    const newInfoConfig: ComponentConfig[] = [
      ...infoConfig,
      {
        component: (
          <InputList
            deletable={true}
            onClick={() => handleDelete(id)}
            title={"Refinance details"}
            infoLists={[
              {
                title: "",
                id: id,
                context: context,
                onBlur: (key, e) =>
                  handleOnBlur(
                    key,
                    e,
                    id,
                    setErrorMessages,
                    setNewRefinancingDetails,
                  ),
              },
            ]}
          />
        ),
        order: order,
      },
    ];
    return newInfoConfig;
  };

  const columns: TableColumn[] = [
    { title: "Filename", key: "filename" },
    { title: "", key: "attachment" },
  ];

  const createOverviewTab = () => {
    let inputOrder = 0;
    if (newRefinancingDetails.length === 0) {
      refinanceDetailsId.current++;
      const emptyInputFields: RefinancingDetails = {
        accountNumber: "",
        bankName: "",
        paymentReference: "",
        amount: "",
      };
      newRefinancingDetails.push({
        ...emptyInputFields,
        id: refinanceDetailsId.current.toString(),
        order: 0,
      });
    }

    for (const [, details] of newRefinancingDetails.entries()) {
      inputOrder++;
      const { id, order, ...visibleDetails } = details;
      if (typeof visibleDetails.amount === "string") {
        amountSum +=
          visibleDetails.amount === ""
            ? 0
            : Number.parseFloat(visibleDetails.amount);
      } else {
        amountSum += visibleDetails.amount;
      }
      const input = (
        <InputList
          deletable={true}
          onClick={() => handleDelete(id)}
          title={"Refinance details"}
          infoLists={[
            {
              title: "",
              context: visibleDetails,
              onBlur: (key, e) =>
                handleOnBlur(
                  key,
                  e,
                  id,
                  setErrorMessages,
                  setNewRefinancingDetails,
                ),
              id: id,
            },
          ]}
        />
      );

      infoConfig.push({ component: input, order: inputOrder });
    }

    infoConfig.push({
      component: <GenericButton onClick={addNewInput} type="AddNewButton" />,
      order: inputOrder + 1,
    });
  };

  const createFilesTab = () => {
    infoConfig.push(
      {
        component: createFilesTableComponent(
          customerUploadedDocuments,
          "Documents uploaded by customer",
          user,
        ),
        order: 1,
        hide: customerUploadedDocuments?.length === 0,
      },
      {
        component: createFilesTableComponent(
          uploadedFiles,
          "Documents uploaded by caseworker",
          { ...user, profile: { ...user.profile, role: "admin-es" } }, // set role to admin-es to show delete button
        ),
        order: 2,
        hide: uploadedFiles?.length === 0,
      },
      {
        component: (
          <div>
            {isLoading ? (
              <LoadingSpinner width={24} height={24} />
            ) : (
              <UploadFile onFileUpload={handleFileUpload} />
            )}
          </div>
        ),
        order: 3,
      },
    );
  };

  const setColumnsForUserTask = () => {
    if (showOverview && infoConfig.length > 2) {
      return 2;
    }
    if (showOverview && infoConfig.length === 2) {
      return 1;
    }

    if (!showOverview) {
      return 1;
    }
  };

  const createFilesTableComponent = (
    files: DynamicTableFileViewProps[] | undefined,
    title: string,
    user: any,
  ) => {
    const handleRowClick = (item: DynamicTableFileViewProps) => {
      setShowFile(true);
      setClickedItem(item);
    };

    const handleRemoveFile = async (item: DynamicTableFileViewProps) => {
      setIsLoading(true);
      setClickedItem(item);
      const messageTasks = await getMessageTasks(flowId);
      const mapAttachmentTask = messageTasks.find(
        (task: any) => task.taskType === "caseworker-upload-document",
      );
      await triggerMessageTask(mapAttachmentTask.taskId, {
        ...item,
        fileId: "",
        filename: item.filename,
        deleteAttachment: true,
      });
      setUploadedFiles((prevFiles) => {
        const newFiles = prevFiles.filter(
          (file) => file.filename !== item.filename,
        );
        return [...newFiles];
      });
      setCustomerUploadedDocuments((prevFiles) => {
        const newFiles = prevFiles.filter(
          (file) => file.filename !== item.filename,
        );
        return [...newFiles];
      });
      setIsLoading(false);
    };

    return (
      <div>
        <FlowContextProvider value={{ flow }}>
          <FlowContext.Provider value={props}>
            <DynamicTable
              handleDelete={(item) => handleRemoveFile(item)}
              handleRowClick={(item) => handleRowClick(item)}
              data={files ?? []}
              columns={columns}
              flowId={flowId}
              mainHeader={title}
              user={user}
            />
          </FlowContext.Provider>
        </FlowContextProvider>
      </div>
    );
  };

  if (showOverview) {
    createOverviewTab();
  } else {
    createFilesTab();
  }

  return showFile ? (
    <div
      style={{
        maxWidth: "90vw",
        maxHeight: "80vh",
        padding: "10px",
      }}
    >
      <CollapseableInfoHeader style={{ borderRadius: "8px" }}>
        {clickedItem.filename}
        <CloseCircle onClick={() => setShowFile(false)} />
      </CollapseableInfoHeader>
      <CollapseableInfoContent>
        <PdfViewer item={clickedItem} shouldShow={true} flowId={flowId} />
      </CollapseableInfoContent>
    </div>
  ) : (
    <div>
      <TabContainer>
        <TabButton
          onClick={() => handleTabView("Overview")}
          label={"Overview"}
          selected={showOverview}
        />
        <TabButton
          onClick={() => handleTabView("Files")}
          label={"Files"}
          selected={!showOverview}
        />
      </TabContainer>
      <CommonUserTaskNew
        handleComplete={onComplete}
        handleSave={onSave}
        infoConfig={infoConfig}
        formConfig={formConfig}
        overrideMasonryColumns={setColumnsForUserTask()}
      />
    </div>
  );
};

const AppWrapper = (props: any) => (
  <QueryClientProvider client={queryClient}>
    <PoaAndRefinanceDocumentation {...props} />
  </QueryClientProvider>
);

export default AppWrapper;
