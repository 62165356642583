import styled from "styled-components";
import { Colors } from "@flow/style";
import type { ExpandedProps } from "./types";

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexWrapper)`
  flex-direction: column;
`;

export const ContentFlexWrapper = styled(FlexWrapper)`
  height: 100%;
  overflow: auto;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const FlexAlignCenterWrapper = styled(FlexWrapper)`
  align-items: center;
`;

export const ButtonZone = styled(FlexWrapper)`
  justify-content: center;
  margin: 50px;
  > Button {
    margin: 10px;
    height: 50px;
  }
`;

export const InfoText = styled.p`
  max-width: 500px;
  padding: 50px 100px 0px 100px;
`;

export const TablesArea = styled(FlexWrapper)`
  margin-top: 100px;
  justify-content: center;
  gap: 100px;
`;

export const AccountInfo = styled.div`
  margin: 20px;
`;

export const TableWrapper = styled.div`
  overflow-y: scroll;
  height: 50vh;
`;

export const TabsWrapper = styled.div`
  margin-left: 20px;
`;

export const CustomerInfo = styled.p`
  padding: 30px;
`;

export const ContentWrapper = styled.div`
  color: ${Colors.Grey1};
  justify-content: center;
`;

export const Td = styled.td`
  padding: 20px;
`;

export const TableHeader = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px;
  background-color: ${Colors.Grey3};
  color: ${Colors.Coal};
`;

export const StyledTable = styled.table`
  // Main Table Element
  border-collapse: separate;
  padding: 20px;
  margin: auto;
  font-size: 1em;
  min-width: 400px;
  height: 100px;
  overflow-y: scroll;

  > thead tr {
    // Header
    text-align: left;
  }

  // Table Rows
  & > tbody tr {
    border-bottom: 1px solid ${Colors.Snow};
  }

  & > tbody tr:nth-of-type(even) {
    background-color: ${Colors.Grey4};
  }
`;

export const ErrorMessageBox = styled.div`
  font-family: sans-serif;
  background-color: ${Colors.Grey1};
  color: ${Colors.Snow};
  padding: 15px;
  max-width: 50vw;
  overflow: auto;
  max-height: 100%;
`;

export const getColorForEnvironment = () => {
  const getEnvironment = () => {
    if (location.hostname === "localhost") return "dev";
    return window.location.hostname.split(".")[1];
  };

  const environment = getEnvironment();
  switch (true) {
    case environment.includes("dev"):
      return "#ecf2ff";
    default:
      return "#F0FAF1";
  }
};

// Message styles
export const CallWrapper = styled(FlexWrapper)`
  justify-content: end;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 300;
`;

export const MessageWrapper = styled(FlexWrapper)`
  margin-bottom: 10px;
`;

export const MessageContainer = styled.div`
  background-color: #ffffff;
  max-width: 450px;
  box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2),
    0px 5px 10px rgba(184, 184, 184, 0.05);
  border-radius: 5px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 10px;

  @media only screen and (max-width: 1250px) {
    max-width: 85%;
  }
`;

export const MessageHeader = styled(FlexAlignCenterWrapper)`
  background-color: #f7fbff;
  justify-content: end;
  padding: 11px 15px;
  font-size: 10px;
  line-height: 125%;
  color: rgba(51, 51, 51, 0.5);
`;

export const MessageHeaderBold = styled.b`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 700;
`;

export const MessageBody = styled.div`
  padding: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #474847;
  white-space: pre-line;
  word-break: break-word;
`;

export const MessageIcon = styled(FlexAlignCenterWrapper)`
  justify-content: center;
  height: 25px;
  width: 25px;
  margin-top: auto;
  margin-right: 8px;
  background-color: #609ef2;
  color: #ffffff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
`;

// InfoSection styles
export const InfoSectionWrapper = styled.div`
  flex-grow: 1;
  background-color: #f8f8f8;
  border-right: 2px solid #e4e2e2;
`;

//DecisionSection styles
export const DecisionSectionWrapper = styled.div`
  width: 350px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const DecisionSectionDivWrapper = styled.div`
  margin: 25px 25px 25px 25px;
`;

export const DecisionSectionPersonWrapper = styled(FlexAlignCenterWrapper)`
  width: 100%;
  border-radius: 4px;
  background-color: ${getColorForEnvironment()};
  height: 50px;
  margin-top: 15px;
`;

export const DecisionSectionPersonInfoWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

export const DecisionSectionPersonInfoDivWrapper = styled(FlexWrapper)`
  flex-direction: column;
`;

export const DecisionSectionPersonInfoAnchor = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2f80ed;
  text-decoration: none;
`;

export const DecisionSectionPersonApplicantWrapper = styled(
  FlexAlignCenterWrapper,
)`
  justify-content: center;
  width: 30px;
  background-color: rgba(37, 86, 43, 0.12);
  border-radius: 4px 0px 0px 4px;
  height: 100%;
  padding: 0px 10px;
`;

export const DecisionSectionPersonApplicantBold = styled.b`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #25562b;
`;

export const CM1RawData = styled.div`
  font-family: sans-serif;
  background-color: ${Colors.Grey1};
  color: ${Colors.Snow};
  padding: 15px;
  max-width: 50vw;
  overflow: auto;
  max-height: 100%;
`;

export const SVGArrowWrapper = styled.div`
  path {
    fill: #2969ff;
  }
`;

export const NormalInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  border-bottom: 1px solid #dad9d8;

  &:last-child {
    border-bottom: none;
  }
`;

export const SliderContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  text-align: center;
`;

export const SliderInput = styled.input`
  flex-grow: 1;
  width: 50%;
  justify-content: space-between;
`;

export const SliderLabel = styled.b`
  font-size: 12px;
  color: #333;
  margin: 2rem 2rem;
`;

export const CommonUserTaskContainer = styled.div`
  display: flex;
  border-radius: 8px;
  max-height: 75vh;
  max-width: 95vw;
`;

export const ExpandContainer = styled.div<ExpandedProps>`
  position: absolute;
  left: -35px;
  background-color: ${(props: any) => (props.expanded ? "#F4F4F4" : "#fff")};
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0 0 4px;
`;

type InfoSectionProps = {
  singleComponent?: boolean;
};

export const InfoSection = styled.div<InfoSectionProps>`
  padding: 32px;
  background-color: #f4f4f4;
  column-count: 2;
  column-gap: 16px;
  row-gap: 16px;
  /* height: 100%; */
  overflow-x: auto;

  display: ${(props: any) => props.singleComponent && "flex"};
  justify-content: ${(props: any) => props.singleComponent && "center"};

  @media screen and (max-width: 1250px) {
    column-count: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const FormSection = styled.div`
  padding: 28px 0;
  min-width: 350px;
  width: 350px;
  gap: 28px;
  display: flex;
  flex-direction: column;
`;

export const InfoSectionComponentContainer = styled.div`
  break-inside: avoid-column;
  padding: 8px 0;

  @media screen and (max-width: 1250px) {
    padding: 0;
  }
`;
