import {
  AttachmentsTab,
  ErrorsAttachmentsTab,
} from "../views/attachments/AttachmentsTab";
import ManualIdReview from "../views/tasks/manual-id-review";
import ManualApplicationReview from "../views/tasks/manual-payout-approval";
import ManualCheckFinal from "../views/tasks/manual-check-final";
import ManualPepReview from "../views/tasks/manual-pep-review";
import ChangeApplication from "../views/tasks/trigger-change-application";
import ChangeLoanAmount from "../views/tasks/trigger-change-loan-amount";
import ContactApplicant from "../views/tasks/trigger-communication-process";
import OverviewTab from "../views/overview";
import { Icons } from "@flow/icons";
import { QueueItem } from "../components/Queue/QueueItem";
import type { Flow } from "@flow";
import AppWrapper from "../views/tasks/poa-and-refinance-documentation";

const CustomComponents = () => {
  const pollingInterval = 60000;

  // Date 14 days ago, used for rejected queue
  const today = new Date();
  const date14DaysAgo = new Date(
    today.setDate(today.getDate() - 14),
  ).toISOString();

  return {
    case: {
      businessStatus: {
        map: (flow: Flow) => {
          return flow.data.businessStatus;
        },
      },
      tabs: [
        {
          id: "attachmentsTab",
          title: "Attachments",
          component: AttachmentsTab,
          hide: (flow: Flow) => flow.numAttachments <= 0,
        },
        {
          id: "errorsTab",
          title: "Errors",
          component: ErrorsAttachmentsTab,
          hide: (flow: Flow) =>
            (flow.flowDefinitionId !== "cleanup" &&
              flow.flowDefinitionId !== "manual-cleanup") ||
            flow.numAttachments <= 0,
        },
      ],
      tasks: {
        taskComponents: {
          "manual-pep-review": {
            titleMap: (task: any) => "Manual AML / PEP Review",
            component: ManualPepReview,
            size: "small",
            customWidth: "90%",
            customHeight: "90%",
          },
          "manual-payout-approval": {
            titleMap: (task: any) => "Manual Payout Approval",
            component: ManualApplicationReview,
            size: "small",
            customWidth: "90%",
            customHeight: "90%",
          },
          "manual-id-review": {
            titleMap: (task: any) => "Manual ID Review",
            component: ManualIdReview,
            size: "small",
            customWidth: "90%",
            customHeight: "90%",
          },
          "verify-refinance-documentation": {
            titleMap: (task: any) => "Verify refinancing details",
            component: AppWrapper,
            customWidth: "max-content",
            customHeight: "max-content",
          },
          "manual-check-final": {
            titleMap: (task: any) => "Final check before disbursement",
            size: "small",
            component: ManualCheckFinal,
            customHeight: "max-content",
          },
          "application-updated": {
            disabled: true,
          },
          "trigger-communication-process": {
            titleMap: (task: any) => "Contact applicant",
            component: ContactApplicant,
          },
          "trigger-change-application": {
            titleMap: (task: any) => "Change application",
            component: ChangeApplication,
          },
          "trigger-change-loan-amount": {
            titleMap: (task: any) => "Change loan amount",
            component: ChangeLoanAmount,
          },
        },
      },

      caseSummary: {
        component: OverviewTab,
      },
    },
    queue: {
      queueItem: QueueItem,
    },
    queueDefinition: {
      "declined-cases-queue": {
        label: "Rejected (Last 14 days)",
        name: "declined-cases-queue",
        queueFilter: `?flowDefinitionId=loan-es&view=queue-items&status[]=completed&status[]=archived&data.queues.disbursed=false&updatedAt=>${date14DaysAgo}`,
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "ready-for-disbursement-cases-queue": {
        label: "Ready for disbursement",
        name: "ready-for-disbursement-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.ready-for-disbursement=true",
        queueSort: "sort=createdAt&dir=-1",
        limit: 20,
      },
      "disbursed-cases-queue": {
        label: "Disbursed",
        name: "disbursed-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&data.queues.disbursed=true",
        //Sort by disbursed date
        queueSort: "sort=completedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "awaiting-accept-cases-queue": {
        label: "Accept Loan Offer",
        name: "awaiting-accept-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.awaiting-accept=true",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "awaiting-signing-cases-queue": {
        label: "Sign Documents",
        name: "awaiting-signing-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.awaiting-signing=true",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "confirm-sef-schema-queue": {
        label: "Confirm SEF Schema",
        name: "confirm-sef-schema-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.confirm-sef-schema=true",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "awaiting-verification-cases-queue": {
        label: "Verify IBAN",
        name: "awaiting-verification-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=confirm-account-number",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "awaiting-confirm-identity-cases-queue": {
        label: "Confirm Identity",
        name: "awaiting-confirm-identity-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=confirm-identity",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "active-cases-queue": {
        label: "Active",
        name: "active-cases-queue",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&hasIncidents=false",
        queueSort: "sort=updatedAt&dir=-1",
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "failed-cases-queue": {
        label: "Incidents",
        name: "failed-cases-queue",
        queueFilter:
          "?flowDefinitionId=!cleanup&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=!",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "failed-cases-update-agent-queue": {
        label: "Incidents (Update Agent)",
        name: "failed-cases-update-agent-queue",
        queueFilter:
          "?flowDefinitionId=!cleanup&view=queue-items&status=active&hasIncidents=true&data.errors.update-agent=",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "cleanup-process": {
        label: "Cleanup Job",
        name: "cleanup-process",
        queueFilter: "?flowDefinitionId=cleanup&view=queue-items",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "manual-cleanup-process": {
        label: "Manual Cleanup",
        name: "manual-cleanup-process",
        queueFilter: "?flowDefinitionId=manual-cleanup&view=queue-items",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "upload-refinance-documentation": {
        label: "Upload refinance documentation",
        name: "upload-refinance-documentation",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.businessStatus=upload-refinance-documentation",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      // Caseworker tasks.
      "manual-casework-aml": {
        label: "AML Review",
        name: "manual-casework-aml",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-pep-review=true",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "manual-check-final": {
        label: "Disbursement Check",
        name: "manual-check-final",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-check-final=true",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "manual-casework-review": {
        label: "Income Review",
        name: "manual-casework-review",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-payout-approval=true",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "manual-id-review": {
        label: "ID Review",
        name: "manual-id-review",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-id-review=true",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
      "manual-check-debt-consolidation": {
        label: "Debt Consolidation",
        name: "manual-check-debt-consolidation",
        queueFilter:
          "?flowDefinitionId=loan-es&view=queue-items&status=active&data.queues.manual-check-debt-consolidation=true",
        queueSort: "sort=updatedAt&dir=-1",
        pollingEnabled: false,
        limit: 20,
        pollingInterval: pollingInterval,
      },
    },
    menu: {
      menuStructure: [
        {
          name: "All Applications",
          isDefaultOpen: true,
          elems: [
            "active-cases-queue",
            "ready-for-disbursement-cases-queue",
            "disbursed-cases-queue",
            "declined-cases-queue",
          ],
          icon: Icons.Withdrawal,
        },
        {
          name: "Caseworker Tasks",
          isDefaultOpen: true,
          elems: [
            "manual-casework-review",
            "manual-casework-aml",
            "manual-id-review",
            "manual-check-final",
            "manual-check-debt-consolidation",
          ],
          icon: Icons.ManualWork,
        },
        {
          name: "Customer Tasks",
          elems: [
            "awaiting-accept-cases-queue",
            "awaiting-verification-cases-queue",
            "awaiting-confirm-identity-cases-queue",
            "confirm-sef-schema-queue",
            "upload-refinance-documentation",
            "awaiting-signing-cases-queue",
          ],
          icon: Icons.Smiley,
        },
        {
          name: "Incidents",
          isDefaultOpen: true,
          elems: ["failed-cases-queue", "failed-cases-update-agent-queue"],
          icon: Icons.Alert,
        },
        {
          name: "cleanup-process",
          icon: Icons.Trashcan,
        },
        {
          name: "manual-cleanup-process",
          icon: Icons.Trashcan,
        },
      ],
    },
  };
};

export { CustomComponents };
